import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAsiYMy2cV5jtBE1_cRcLo9k1nHYA-IQdM",
  authDomain: "turtle-i-7978a.firebaseapp.com",
  projectId: "turtle-i-7978a",
  storageBucket: "turtle-i-7978a.appspot.com",
  messagingSenderId: "874603195776",
  appId: "1:874603195776:web:ea26235e21aa8837f72565",
  measurementId: "G-YD2GN4JRBG"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, db, analytics };